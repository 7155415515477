import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import { routes } from 'routes'
import { useUnreadStatus } from 'hooks/useLiveUpdates'
import { BadgeNav } from 'components/appShell/header/components/items/statusIcons/BadgeNav'
import { ConversationsIcon } from 'components/icons/Conversations'

export const MessageBubble = () => {
    const [unreadStatus] = useUnreadStatus()
    const totalUnreadMessages = unreadStatus?.total || 0

    return (
        <Link href={routes.conversationsAll()} passHref>
            <ChakraLink
                position="relative"
                variant="menuItem"
                lineHeight="20px"
                p="8px"
                title="Conversations"
                aria-label="Conversations"
            >
                {totalUnreadMessages > 0 && <BadgeNav>{totalUnreadMessages}</BadgeNav>}
                <ConversationsIcon w="23px" h="20px" strokeWidth={1.5} />
            </ChakraLink>
        </Link>
    )
}
