import React from 'react'
import { LayoutProps, chakra } from '@chakra-ui/react'
import { StandardVertical } from 'entities/vertical'
import AITextBlack from 'public/img/verticals/ai_text_black.svg'
import AITextWhite from 'public/img/verticals/ai_text_white.svg'
import BlockchainTextBlack from 'public/img/verticals/blockchain_text_black.svg'
import BlockchainTextWhite from 'public/img/verticals/blockchain_text_white.svg'
import FunctionalTextBlack from 'public/img/verticals/functional_text_black.svg'
import FunctionalTextWhite from 'public/img/verticals/functional_text_white.svg'
import GolangTextBlack from 'public/img/verticals/golang_text_black.svg'
import GolangTextWhite from 'public/img/verticals/golang_text_white.svg'
import JavaTextBlack from 'public/img/verticals/java_text_black.svg'
import JavaTextWhite from 'public/img/verticals/java_text_white.svg'
import JavaScriptTextBlack from 'public/img/verticals/javascript_text_black.svg'
import JavaScriptTextWhite from 'public/img/verticals/javascript_text_white.svg'
import PythonTextBlack from 'public/img/verticals/python_text_black.svg'
import PythonTextWhite from 'public/img/verticals/python_text_white.svg'
import RemoteTextBlack from 'public/img/verticals/remote_text_black.svg'
import RemoteTextWhite from 'public/img/verticals/remote_text_white.svg'
import CompanyTextBlack from 'public/img/verticals/www_text_black.svg'
import CompanyTextWhite from 'public/img/verticals/www_text_white.svg'

const AITextBlackChakra = chakra(AITextBlack)
const AITextWhiteChakra = chakra(AITextWhite)
const BlockchainTextBlackChakra = chakra(BlockchainTextBlack)
const BlockchainTextWhiteChakra = chakra(BlockchainTextWhite)
const CompanyTextBlackChakra = chakra(CompanyTextBlack)
const CompanyTextWhiteChakra = chakra(CompanyTextWhite)
const FunctionalTextBlackChakra = chakra(FunctionalTextBlack)
const FunctionalTextWhiteChakra = chakra(FunctionalTextWhite)
const GolangTextBlackChakra = chakra(GolangTextBlack)
const GolangTextWhiteChakra = chakra(GolangTextWhite)
const JavaTextBlackChakra = chakra(JavaTextBlack)
const JavaTextWhiteChakra = chakra(JavaTextWhite)
const JavaScriptTextBlackChakra = chakra(JavaScriptTextBlack)
const JavaScriptTextWhiteChakra = chakra(JavaScriptTextWhite)
const PythonTextBlackChakra = chakra(PythonTextBlack)
const PythonTextWhiteChakra = chakra(PythonTextWhite)
const RemoteTextBlackChakra = chakra(RemoteTextBlack)
const RemoteTextWhiteChakra = chakra(RemoteTextWhite)

export const VerticalText = ({
    vertical,
    color = 'black',
    ...layoutProps
}: {
    vertical: StandardVertical
    color?: 'black' | 'white'
} & LayoutProps) => {
    const props = {
        width: 'auto',
        ...layoutProps,
    }

    switch (vertical) {
        case 'functional':
            return color === 'black' ? (
                <FunctionalTextBlackChakra {...props} />
            ) : (
                <FunctionalTextWhiteChakra {...props} />
            )
        case 'blockchain':
            return color === 'black' ? (
                <BlockchainTextBlackChakra {...props} />
            ) : (
                <BlockchainTextWhiteChakra {...props} />
            )
        case 'javascript':
            return color === 'black' ? (
                <JavaScriptTextBlackChakra {...props} />
            ) : (
                <JavaScriptTextWhiteChakra {...props} />
            )
        case 'ai':
            return color === 'black' ? (
                <AITextBlackChakra {...props} />
            ) : (
                <AITextWhiteChakra {...props} />
            )
        case 'golang':
            return color === 'black' ? (
                <GolangTextBlackChakra {...props} />
            ) : (
                <GolangTextWhiteChakra {...props} />
            )
        case 'java':
            return color === 'black' ? (
                <JavaTextBlackChakra {...props} />
            ) : (
                <JavaTextWhiteChakra {...props} />
            )
        case 'python':
            return color === 'black' ? (
                <PythonTextBlackChakra {...props} />
            ) : (
                <PythonTextWhiteChakra {...props} />
            )
        case 'remote':
            return color === 'black' ? (
                <RemoteTextBlackChakra {...props} />
            ) : (
                <RemoteTextWhiteChakra {...props} />
            )
        default:
            return color === 'black' ? (
                <CompanyTextBlackChakra {...props} />
            ) : (
                <CompanyTextWhiteChakra {...props} />
            )
    }
}
