import React, { ReactNode, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Link as ChakraLink,
    Flex,
    Grid,
    HStack,
    Heading,
    Icon,
    Input,
    Text,
    VStack,
    chakra,
    useDisclosure,
} from '@chakra-ui/react'
import Link from 'next/link'
import { ArrowRight as ArrowRightIcon, ChevronDown } from '@emotion-icons/feather'
import {
    presetJobsCitiesWithSlugs,
    presetJobsCountriesWithSlugs,
} from 'entities/location'
import { routes } from 'routes'
import { verticalOptions } from 'lib/vertical'
import useVerticalData from 'hooks/useVerticalData'
import { VerticalLogo } from 'components/VerticalLogo'
import EmailIconFooter from 'public/img/email_icon_footer.svg'
import GithubIconFooter from 'public/img/github_icon_footer.svg'
import InstagramIconFooter from 'public/img/instagram_icon_footer.svg'
import LinkedinIconFooter from 'public/img/linkedin_icon_footer.svg'
import LogoFooterText from 'public/img/logo_footer_text.svg'
import TwitterIconFooter from 'public/img/twitter_icon_footer.svg'
import UkIconFooter from 'public/img/uk_icon_footer.svg'
import UsIconFooter from 'public/img/us_icon_footer.svg'

export const Footer = () => {
    return (
        <Grid
            gridArea="footer"
            templateColumns={{ base: '1fr 1fr', xl: '1fr 1fr 1fr 1fr 1fr 2fr' }}
            templateRows={{ base: '', xl: '10fr 1fr 2fr' }}
            gridRowGap={{ base: 0, xl: 2 }}
            backgroundColor="secondary.dark"
            maxWidth="100%"
            height="auto"
            px={{ base: 6, xl: 20 }}
            pt={{ base: 8, xl: 20 }}
            pb={{ base: '38px', xl: '0px' }}
        >
            <FooterWorksHub />
            <FooterCompanies />
            <FooterJobs />
            <FooterLocations />
            <FooterArticles />
            <FooterAbout />
            <FooterStep />
            <FooterBottom />
        </Grid>
    )
}

const FooterWorksHub = () => {
    const verticalData = useVerticalData()
    return (
        <FooterLinksCol
            label="WorksHub"
            gridRow={{ base: '1/2', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '1/2' }}
        >
            <FooterLink href="https://hire.withgoogle.com/public/jobs/functionalworkscom">
                Careers
            </FooterLink>
            <FooterLink href={routes.companies()}>Companies</FooterLink>
            <FooterLink href={routes.sitemap()}>Sitemap</FooterLink>
            {verticalOptions.map(community => (
                <FooterLink
                    key={community.value}
                    href={verticalData?.allVerticalUrls?.[community.value]}
                >
                    {community.label}
                </FooterLink>
            ))}
        </FooterLinksCol>
    )
}

const FooterCompanies = () => {
    return (
        <FooterLinksCol
            label="For companies"
            gridRow={{ base: '2/3', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '2/3' }}
        >
            <FooterLink href={routes.hiring()}>For employers</FooterLink>
            <FooterLink href={routes.companyRegistration()}>Register company</FooterLink>
            <FooterLink href={routes.pricing()}>Pricing and plans</FooterLink>
            <FooterLink href="https://app.hubspot.com/meetings/workshub/product-demo">
                Request a demo
            </FooterLink>
            <FooterLink href={routes.termsOfService()}>Terms of Service</FooterLink>
        </FooterLinksCol>
    )
}

const FooterJobs = () => {
    const verticalData = useVerticalData()

    return (
        <FooterLinksCol
            label="Jobs"
            gridRow={{ base: '3/4', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '3/4' }}
        >
            {verticalData?.verticalConfig.footerJobLinks.map(tag => {
                return (
                    <FooterLink
                        key={tag.slug}
                        href={routes.jobsPresetSearch({ tech: tag })}
                    >
                        {tag.label} jobs
                    </FooterLink>
                )
            })}
        </FooterLinksCol>
    )
}

const FooterArticles = () => {
    const verticalData = useVerticalData()

    return (
        <FooterLinksCol
            label="Articles"
            gridRow={{ base: '4/5', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '4/5' }}
        >
            {verticalData?.verticalConfig.footerArticleLinks.map(tag => {
                return (
                    <FooterLink
                        key={tag.slug}
                        href={routes.articlesPresetSearch({ tech: tag })}
                    >
                        {tag.label} articles
                    </FooterLink>
                )
            })}
        </FooterLinksCol>
    )
}

const FooterLocations = () => {
    return (
        <FooterLinksCol
            label="Locations"
            gridRow={{ base: '5/6', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '5/6' }}
        >
            {[...presetJobsCountriesWithSlugs, ...presetJobsCitiesWithSlugs].map(
                presetLocation => (
                    <FooterLink
                        key={presetLocation.slug}
                        href={routes.jobsPresetSearch({ location: presetLocation })}
                    >
                        Jobs in {presetLocation.label}
                    </FooterLink>
                ),
            )}
            <FooterLink key="remote" href={routes.jobsPresetSearch({ remote: true })}>
                Remote Jobs
            </FooterLink>
        </FooterLinksCol>
    )
}

const FooterLogo = () => {
    const ChakraLogoFooterText = chakra(LogoFooterText)

    return (
        <Flex alignItems="center" gridGap="15px">
            <VerticalLogo
                w={{ base: '30.5px', xl: '42px' }}
                h={{ base: '30.5px', xl: '40px' }}
            />
            <ChakraLogoFooterText />
        </Flex>
    )
}

const FooterAbout = () => {
    const { register, handleSubmit } = useForm<{ email: string }>()
    const [subscribed, setSubscribed] = useState<boolean>(false)

    const subscribeToNewsLetter = async ({ email }: { email: string }) => {
        const formData = new FormData()
        formData.append('email', email)

        await fetch(routes.api.createProspect(), {
            method: 'POST',
            body: formData,
        })
        setSubscribed(true)
    }

    return (
        <VStack
            alignItems="stretch"
            gridRow={{ base: '6/7', xl: '1/2' }}
            gridColumn={{ base: '1/3', xl: '6/7' }}
            mt={{ base: 8, xl: 0 }}
        >
            <HStack mb={{ base: '15px', xl: '44px' }}>
                <FooterLogo />
            </HStack>
            <Grid gridTemplateColumns="30px 1fr" rowGap="13px" alignItems="center">
                <EmailIconFooter />
                <ChakraLink
                    color="gray.300"
                    fontWeight={{ base: '500', xl: '600' }}
                    fontSize="13px"
                    href="mailto:hello@works-hub.com"
                >
                    hello@works-hub.com
                </ChakraLink>
                <UkIconFooter />
                <Text
                    color="gray.300"
                    fontWeight={{ base: '500', xl: '600' }}
                    fontSize="13px"
                >
                    Ground Floor, Verse Building, 18 Brunswick Place, London, N1 6DZ
                </Text>
                <UsIconFooter />
                <Text
                    color="gray.300"
                    fontWeight={{ base: '500', xl: '600' }}
                    fontSize="13px"
                >
                    108 E 16th Street, New York, NY 10003
                </Text>
            </Grid>
            <VStack alignItems="flex-start" mb="20px">
                <Heading
                    mt={{ base: '36px', xl: '50px' }}
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                    color="white"
                >
                    Subscribe to our newsletter
                </Heading>
                <Text
                    mt={{ base: '14px', xl: '16px' }}
                    color="gray.300"
                    fontWeight={{ base: '500', xl: '600' }}
                    fontSize="13px"
                    lineHeight="20px"
                >
                    Join over 111,000 others and get access to exclusive content, job
                    opportunities and more!
                </Text>
            </VStack>
            {subscribed ? (
                <Text fontWeight="bold" color="white" py={8}>
                    You have successfully subscribed to our newsletter!
                </Text>
            ) : (
                <Box
                    as="form"
                    position="relative"
                    alignSelf="flex-start"
                    width={{ base: '100%', xl: '360px' }}
                    maxWidth={{ base: '580px', xl: undefined }}
                    mt="16px !important"
                    pb={{ base: 8, xl: 0 }}
                    onSubmit={handleSubmit(subscribeToNewsLetter)}
                >
                    <Input
                        variant="unstyled"
                        type="email"
                        height="45px"
                        borderRadius="50px"
                        fontWeight={600}
                        fontSize="13px"
                        color="secondary.dark"
                        backgroundColor="#E7E9FDE5"
                        placeholder="Enter your email"
                        _placeholder={{ color: 'secondary.dark', fontWeight: '600' }}
                        _focus={{ backgroundColor: 'secondary.200' }}
                        p="12px 50px 14px 21px"
                        {...register('email')}
                        isRequired
                    />
                    <Button
                        type="submit"
                        backgroundColor="secondary.700"
                        color="white"
                        colorScheme="secondary"
                        position="absolute"
                        right="3px"
                        top="2px"
                        height="41px"
                        width="41px"
                        p="1px"
                        zIndex="popover"
                        borderRadius="50%"
                    >
                        <ArrowRightIcon size="24px" strokeWidth="1.5px" />
                    </Button>
                </Box>
            )}
            <FooterMobileStep />
        </VStack>
    )
}

const FooterStep = () => {
    return (
        <Box
            height="1px"
            backgroundColor="gray.bg_dark"
            opacity="30%"
            width="100%"
            gridColumn={{ base: '1/3', xl: '1/7' }}
            mx="auto"
            alignSelf="end"
        />
    )
}

const FooterBottom = () => {
    return (
        <>
            <Flex
                gridColumn={'1/6'}
                gap={{ base: '12px', md: '48px' }}
                marginTop={'21px'}
                flexDirection={{ base: 'column', md: 'row' }}
            >
                <Text
                    color="gray.300"
                    fontWeight={{ base: '500', xl: '600' }}
                    fontSize="13px"
                    lineHeight="20px"
                >
                    &copy; {new Date().getFullYear()} WorksHub
                </Text>
                <Link href={routes.privacyPolicy()} passHref>
                    <ChakraLink
                        target="_blank"
                        color="gray.300"
                        fontWeight={{ base: '500', xl: '600' }}
                        fontSize="13px"
                        lineHeight="20px"
                    >
                        Privacy Policy
                    </ChakraLink>
                </Link>
                <Link href="https://www.works-hub.com/" passHref>
                    <ChakraLink
                        target="_blank"
                        color="gray.300"
                        fontWeight={{ base: '500', xl: '600' }}
                        fontSize="13px"
                        lineHeight="20px"
                    >
                        Developed by WorksHub
                    </ChakraLink>
                </Link>
            </Flex>
            <Flex
                gridColumn={{ base: '1/3', xl: '6/7' }}
                gridRow={{ base: '7/8', xl: '3/4' }}
                my={{ base: '14px', xl: '' }}
                gap={{ base: '40px', xl: '52px' }}
            >
                <FooterSocialMediaLink url="https://www.linkedin.com/company/workshub">
                    <LinkedinIconFooter />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink url="https://twitter.com/@workshub">
                    <TwitterIconFooter />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink url="https://www.instagram.com/works_hub">
                    <InstagramIconFooter />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink url="https://github.com/workshub">
                    <GithubIconFooter />
                </FooterSocialMediaLink>
            </Flex>
        </>
    )
}

const FooterSocialMediaLink = ({
    children,
    url,
}: {
    children: React.ReactNode
    url: string
}) => {
    return (
        <Link href={url} passHref>
            <a target="_blank">{children}</a>
        </Link>
    )
}

const FooterLinksCol = ({
    label,
    gridRow,
    gridColumn,
    children,
}: {
    label: string
    gridRow: { base: string; xl: string }
    gridColumn: { base: string; xl: string }
    children: ReactNode
}) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false })

    return (
        <VStack alignItems="flex-start" gridRow={gridRow} gridColumn={gridColumn}>
            <Heading
                display={{ base: 'none', xl: 'flex' }}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={{ base: '8px', xl: '16px' }}
                fontWeight={{ base: '600', xl: '700' }}
                fontSize={{ base: '16px', xl: '13px' }}
                lineHeight={{ base: '24px', xl: '20px' }}
                color="white"
                as="h3"
            >
                {label}
            </Heading>

            <Heading
                display={{ base: 'flex', xl: 'none' }}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={{ base: '8px', xl: '16px' }}
                fontWeight={{ base: '600', xl: '700' }}
                fontSize={{ base: '16px', xl: '13px' }}
                lineHeight={{ base: '24px', xl: '20px' }}
                color="white"
                onClick={onToggle}
                as="button"
                py="10px"
            >
                {label}
                <Icon
                    as={ChevronDown}
                    fontSize="12px"
                    strokeWidth="2px"
                    color="white"
                    display={{ base: 'block', xl: 'none' }}
                    transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                />
            </Heading>

            {isOpen && (
                <VStack
                    display={{ base: 'flex', xl: 'none' }}
                    alignItems="flex-start"
                    spacing={{ base: 2, xl: 1 }}
                    pl={{ base: 4, xl: 0 }}
                    pb="10px"
                >
                    {children}
                </VStack>
            )}

            <VStack
                display={{ base: 'none', xl: 'flex' }}
                alignItems="flex-start"
                spacing={{ base: 2, xl: 1 }}
                pl={{ base: 4, xl: 0 }}
                pb="10px"
            >
                {children}
            </VStack>

            <FooterMobileStep />
        </VStack>
    )
}

const FooterLink = ({ href, children }: { href?: string; children: ReactNode }) => {
    return (
        <Link href={href || '#'} passHref>
            <ChakraLink
                color="gray.300"
                fontWeight={{ base: '500', xl: '600' }}
                fontSize="13px"
                lineHeight="20px"
            >
                {children}
            </ChakraLink>
        </Link>
    )
}

const FooterMobileStep = () => {
    return (
        <Box
            display={{ base: 'block', xl: 'none' }}
            height="1px"
            backgroundColor="gray.bg_dark"
            opacity="30%"
            width="100%"
            mx="auto"
            alignSelf="end"
        />
    )
}
