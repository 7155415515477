import React from 'react'
import { ContinueWithSection } from 'components/appShell/header/components/items/ContinueWithSection'
import { GuestCTA } from 'components/appShell/header/components/items/GuestCTA'
import { NavLink } from 'components/appShell/header/components/items/NavLinks'
import {
    NavMenu,
    NavMenuMobile,
} from 'components/appShell/header/components/items/NavMenu'
import { SearchBar } from 'components/appShell/header/components/items/SearchBar'
import { CompanyApplications } from 'components/appShell/header/components/items/statusIcons/CompanyApplications'
import { MessageBubble } from 'components/appShell/header/components/items/statusIcons/MessageBubble'
import {
    HeaderItemName,
    HeaderItem as HeaderItemType,
    isCustom,
    isMenu,
    isNavLink,
} from 'components/appShell/header/structure'

export const HeaderItem = ({
    name,
    item,
}: {
    name: HeaderItemName
    item: HeaderItemType
}) => {
    if (isNavLink(item)) {
        return <NavLink name={name} label={item.label} url={item.url} tag={item.tag} />
    } else if (isMenu(item)) {
        return <NavMenu name={name} menu={item} />
    } else if (isCustom(item)) {
        switch (name) {
            case 'search':
                return <SearchBar />
            case 'messages':
                return <MessageBubble />
            case 'applications':
                return <CompanyApplications />
            case 'guestCTA':
                return <GuestCTA />
            default:
                throw new Error('Not implemented! Add the case for new header item')
        }
    }
    return null
}

export const HeaderItemMobile = ({
    name,
    item,
}: {
    name: HeaderItemName
    item: HeaderItemType
}) => {
    if (isNavLink(item)) {
        return (
            <NavLink
                name={name}
                label={item.label}
                url={item.url}
                tag={item.tag}
                forMobile={true}
            />
        )
    } else if (isMenu(item)) {
        return <NavMenuMobile name={name} menu={item} />
    } else if (isCustom(item)) {
        switch (name) {
            case 'search':
                return <SearchBar forMobile={true} order={-1} w="100%" pb="14px" />
            case 'messages':
            case 'applications':
                return null
            case 'guestCTA':
                return <ContinueWithSection />
            default:
                throw new Error('Not implemented! Add the case for new header item')
        }
    }
    return null
}
