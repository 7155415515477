import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import { allVerticalsConfig } from 'lib/vertical'
import { useVertical } from 'hooks/useVerticalData'
import { VerticalLogo } from 'components/VerticalLogo'
import { VerticalText } from 'components/VerticalText'

export const DynamicLogo = () => {
    const vertical = useVertical()
    const platformName = allVerticalsConfig[vertical].platformName

    return (
        <Link href="/" passHref>
            <ChakraLink
                flexShrink={0}
                variant="header"
                position="relative"
                width="max-content"
                display="flex"
                alignItems="center"
                gridGap="8px"
                title={platformName}
            >
                <VerticalLogo
                    vertical={vertical}
                    boxSize={{ base: '30.5px', md: '35px' }}
                />
                <VerticalText
                    vertical={vertical}
                    height={{ base: '19.5px', md: '24px' }}
                    maxWidth={{ base: '78px', md: '96px' }}
                />
            </ChakraLink>
        </Link>
    )
}
