import React, { ReactNode } from 'react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import useMe from 'hooks/useMe'
import { FancyLabel } from 'components/appShell/header/components/FancyLabel'
import { getHeaderItemIconInfo } from 'components/appShell/header/components/Icons'
import { HeaderItemName } from 'components/appShell/header/structure'
import SmallTriangle from 'public/img/small_triangle.svg'

const SmallTriangleChakra = chakra(SmallTriangle)

export const MobileMenuStack = ({
    name,
    label,
    children,
}: {
    name: HeaderItemName
    label?: string
    children: ReactNode
}) => {
    const currentUser = useMe()
    const { isOpen, onClose, onOpen } = useDisclosure()

    const icon = getHeaderItemIconInfo(name, currentUser)?.icon

    return (
        <Flex direction="column" w="100%">
            <Flex
                justifyContent="space-between"
                // NB: On mobile, display a wider touch area (width + padding).
                width="100%"
                py="14px"
                onClick={isOpen ? onClose : onOpen}
            >
                <FancyLabel text={label} icon={icon} />
                <Box
                    alignSelf="center"
                    transition="0.3s transform"
                    transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                >
                    <SmallTriangleChakra height="7px" width="12px" size="20" />
                </Box>
            </Flex>
            {isOpen && (
                <Flex
                    direction="column"
                    alignItems="flex-start"
                    width="100%"
                    pl="16px"
                    my="14px"
                    borderLeftWidth="1.5px"
                    borderLeftColor="primary.600"
                    borderLeftStyle="solid"
                >
                    {children}
                </Flex>
            )}
        </Flex>
    )
}
