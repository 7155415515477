import React, { ReactNode } from 'react'
import { Button, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { routes } from 'routes'
import GithubIcon from 'public/img/github_icon.svg'
import MailIcon from 'public/img/mail_icon.svg'
import StackoverflowIcon from 'public/img/stackoverflow_icon.svg'
import TwitterIcon from 'public/img/twitter_icon.svg'

export const ContinueWithSection = () => {
    return (
        <Flex
            direction="column"
            alignItems="center"
            gridGap="18px"
            width="100%"
            my="48px"
        >
            <Heading size="xs" alignSelf="center" fontSize="16px">
                Continue with
            </Heading>
            <Grid gridTemplateColumns="1fr 1fr" gap="8px" minWidth="50vw">
                <ContinueWithButton
                    provider="github"
                    label="GitHub"
                    icon={<GithubIcon width="22px" />}
                />
                <ContinueWithButton
                    provider="twitter"
                    label="Twitter"
                    icon={<TwitterIcon width="22px" />}
                />
                <ContinueWithButton
                    provider="stackoverflow"
                    label="Stack Overflow"
                    icon={<StackoverflowIcon width="22px" />}
                />
                <ContinueWithButton
                    provider="email"
                    label="Email"
                    icon={<MailIcon width="22px" />}
                />
            </Grid>
        </Flex>
    )
}

const ContinueWithButton = ({
    label,
    provider,
    icon,
}: {
    label: string
    provider: 'github' | 'stackoverflow' | 'twitter' | 'email'
    icon: ReactNode
}) => {
    return (
        <Link href={routes.loginWith(provider)} passHref>
            <Button
                as="a"
                variant="secondary"
                colorScheme="grey"
                display="grid"
                gridTemplateColumns="22px 1fr"
                justifyContent="flex-start"
                gridGap="10px"
                py="0"
            >
                {icon}
                <Text
                    fontWeight="600"
                    fontSize="13px"
                    lineHeight="20px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    {label}
                </Text>
            </Button>
        </Link>
    )
}
