import React from 'react'
import { Me } from 'entities/user'
import useMe from 'hooks/useMe'
import {
    MenuPopover,
    ShowSettings,
} from 'components/appShell/header/components/items/menus/MenuPopover'
import { MobileMenuStack } from 'components/appShell/header/components/items/menus/MobileMenuStack'
import { MenuItems } from 'components/appShell/header/components/items/menus/items/MenuItems'
import { getMenuItemsWrapper } from 'components/appShell/header/components/items/menus/items/MenuItemsWrapper'
import { HeaderItemName, Menu, MenuItemName } from 'components/appShell/header/structure'

const doShowHeaderItem = (
    name: HeaderItemName,
    currentUser: Me,
): ShowSettings | undefined => {
    // NB: This adds a header item-specific level of adaptivity.
    switch (name) {
        case 'user':
            const wrapper =
                currentUser?.isCompanyUser || currentUser?.isAdmin
                    ? { base: false, xs: true }
                    : { base: true }
            const triangle = { base: false, lg: true }
            return { wrapper, triangle }
        case 'employer':
            return { wrapper: { base: false, md: true } }
    }
}

export const NavMenu = ({
    name,
    menu,
}: {
    name: HeaderItemName
    menu: Menu<MenuItemName>
}) => {
    const currentUser = useMe()
    const doShow = doShowHeaderItem(name, currentUser)

    const menuItems = <MenuItems headerItemName={name} menu={menu} />
    const MenuItemsWrapper = getMenuItemsWrapper(name)

    return (
        <MenuPopover doShow={doShow} name={name} label={menu.label}>
            <MenuItemsWrapper>{menuItems}</MenuItemsWrapper>
        </MenuPopover>
    )
}

export const NavMenuMobile = ({
    name,
    menu,
}: {
    name: HeaderItemName
    menu: Menu<MenuItemName>
}) => {
    // NB: Expansion is used at mobile menu (see `mobileExpanded`)
    //     and boils down to rendering menu items as header items,
    //     so they are visually identical.
    const expanded = menu.mobileExpand ?? false

    const menuItems = (
        <MenuItems
            headerItemName={name}
            menu={menu}
            forMobile={true}
            expanded={expanded}
        />
    )

    if (expanded) {
        return menuItems
    } else {
        return (
            <MobileMenuStack name={name} label={menu.label}>
                {/* Don't require any wrapping as in `NavMenu` */}
                {/* since it's already provided by the parent. */}
                {menuItems}
            </MobileMenuStack>
        )
    }
}
