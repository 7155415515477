import { Guest, Me, UserType, UserTypeExt, getUserTypeExt } from 'entities/user'
import { Vertical, communityVerticals } from 'entities/vertical'
import { routes } from 'routes'
import { verticalOptions } from 'lib/vertical'
import { UserSpecificProp } from 'components/appShell/header/utils'

// The `Header` structure, which is defined in terms of descriptors and types.
//
// IN A NUTSHELL
//
//   Header         = Header Variants (per user type)
//   Header Variant = Header Sides ('left' & 'right')
//   Header Side    = Header Items
//
//   Header Item    = NavLink | Menu | Custom
//   Menu           = Menu Items
//
// The structure of the `components` directory closely reflects this hierarchy.
//
// The mobile version specifics are reflected in `mobile`-prefixed properties.
//
// HOW TO EXTEND
//
// A practical case for a safe `Header` extension by adding a new Header Item.
//
// 1. for each Header Variant, i.e. user type
//  - decide on the Header Side for the new item
//  - decide on the Header Item type (link? menu? custom?)
//  - update the `headerVariants` descriptor with all of these
//
// 2. address the type errors in this module
//  - update the `HeaderLeftVariant` and/or `HeaderRightVariant` types
//  - for new Menu Header Items
//    - introduce the `...MenuItems` & `...MenuItemName` types
//    - enlist the `...MenuItemName` within the `MenuItemName`
//    - update the `getMenuItem` function implementation
//
// 3. add rendering for Custom Header Items, if any
//  - go to the `components/items/HeaderItems.tsx`
//  - implement it for both versions (desktop and/or mobile)
//
// 4. go to the `components/Icons.tsx`
//  - set up an icon for both Header Item
//  - set up icons for Menu Items, if any

// MENU ITEMS > DESCRIPTORS

const JobsMenuItems = {
    Jobsboard: {
        label: 'Jobsboard',
        url: routes.jobs(),
    },
    AppliedJobs: {
        label: 'Applied jobs',
        url: routes.appliedJobs(),
    },
    SavedJobs: {
        label: 'Saved jobs',
        url: routes.savedJobs(),
    },
    RecommendedJobs: {
        label: 'Recommended jobs',
        url: routes.recommendedJobs(),
    },
    JobsForReview: {
        label: 'Jobs for review',
        url: routes.adminJobsForReview(),
    },
    PostNewJob: {
        label: 'Post a job',
        url: routes.newJob(),
    },
    AllLiveApplications: {
        label: 'All live applications',
        url: (currentUser: Me) => routes.userApplications(currentUser!),
    },
} as const
type JobsMenuItemName = keyof typeof JobsMenuItems

const CompaniesMenuItems = {
    AllCompanies: {
        label: 'All companies',
        url: routes.adminCompanies(),
    },
    PublicCompanies: {
        label: (currentUser: Me) =>
            currentUser?.isAdmin ? 'Public companies' : 'All companies',
        url: routes.companies(),
    },
    CompanyProfile: {
        label: 'Company profile',
        url: (currentUser: Me) => routes.companyProfile(currentUser!.company!.slug),
    },
    CreateCompany: {
        label: 'Create a company',
        url: routes.newCompany(),
    },
} as const
type CompaniesMenuItemName = keyof typeof CompaniesMenuItems

const ArticlesMenuItems = {
    AllArticles: {
        label: 'All articles',
        url: routes.articles(),
    },
    WriteArticle: {
        label: 'Write an article',
        url: routes.newArticle(),
    },
    SavedArticles: {
        label: 'Saved articles',
        url: routes.savedArticles(),
    },
    UnpublishedArticles: {
        label: 'Unpublished articles',
        url: routes.adminArticles(),
    },
} as const
type ArticlesMenuItemName = keyof typeof ArticlesMenuItems

const CandidatesMenuItems = {
    AllCandidates: {
        label: 'All candidates',
        url: routes.candidates(),
    },
    CreateCandidate: {
        label: 'Create a candidate',
        url: routes.newCandidate(),
    },
} as const
type CandidatesMenuItemName = keyof typeof CandidatesMenuItems

const CommunitiesMenuItems = verticalOptions.reduce((acc, opt) => {
    return {
        ...acc,
        [opt.value]: {
            label: opt.label,
            url: '#', // will be set in `getCustomMenuItemProps`
        },
    }
}, {} as Record<Vertical, MenuItem>)
type CommunitiesMenuItemName = keyof typeof CommunitiesMenuItems

const EmployerMenuItems = {
    WhyWorksHub: {
        label: 'Why WorksHub',
        url: 'https://hiring.works-hub.com/',
    },
    PostFirstJob: {
        label: 'Post a job',
        url: routes.companyRegistration(),
        tag: '1st job free',
    },
    PricingAndPlans: {
        label: 'Pricing and plans',
        url: routes.pricing(),
    },
    SignUpCompany: {
        label: 'Sign up an organization',
        url: routes.companyRegistration(),
    },
    Login: {
        label: 'Log in',
        url: routes.login(),
    },
}
type EmployerMenuItemName = keyof typeof EmployerMenuItems

const UserMenuItems = {
    Divider: {}, // a common way to add dividers to Menu Items lists
    UserSnippet: {
        label: (currentUser: Me) => currentUser!.name,
        url: routes.currentUserProfile(),
    },
    ProfileSettings: {
        label: (currentUser: Me) =>
            currentUser?.isCompanyUser ? 'My profile' : 'Profile settings',
        url: routes.currentUserProfile(),
    },
    CompanySnippet: {
        label: (currentUser: Me) => currentUser!.company!.name,
        url: (currentUser: Me) => routes.companyProfile(currentUser!.company!.slug),
    },
    CompanyProfile: {
        label: 'Company profile',
        url: (currentUser: Me) => routes.companyProfile(currentUser!.company!.slug),
    },
    CompanySettings: {
        label: 'Company settings',
        url: routes.companySettings(),
    },
    NotificationSettings: {
        label: 'Notification settings',
        url: routes.notificationSettings(),
    },
    Metrics: {
        label: 'Metrics',
        url: routes.metrics(),
    },
    Feed: {
        label: 'Feed',
        url: routes.feed(),
    },
    Logout: {
        label: 'Logout',
        url: routes.api.logout(),
    },
} as const
type UserMenuItemName = keyof typeof UserMenuItems

// MENU ITEMS > TYPES

export type MenuItemName =
    | JobsMenuItemName
    | CompaniesMenuItemName
    | ArticlesMenuItemName
    | CandidatesMenuItemName
    | CommunitiesMenuItemName
    | EmployerMenuItemName
    | UserMenuItemName

// NB: Differs from the `NavLink` in user specificity.
export type MenuItem = {
    /**
     * The Menu Item UI label, user-specific.
     */
    readonly label: UserSpecificProp
    /**
     * The Menu Item destination URL, user-specific.
     */
    readonly url: UserSpecificProp
    /**
     * The Menu Item distinctive tag, e.g. "1st job free".
     */
    readonly tag?: string
}

// HEADER ITEMS > TYPES

/**
 * A Header Item subtype that ends up being rendered as a link.
 */
export type NavLink = {
    /**
     * The link UI label.
     */
    readonly label: string
    /**
     * The link destination URL.
     */
    readonly url: string
    /**
     * The link distinctive tag, e.g. "1st job free".
     */
    readonly tag?: string
}

/**
 * A Header Item subtype that ends up being rendered as a menu.
 */
export type Menu<T extends MenuItemName> = {
    /**
     * The menu UI label.
     */
    readonly label?: string
    /**
     * A default list of Menu Items.
     */
    readonly items: T[]
    /**
     * An optional list of Menu Items for mobile version.
     */
    readonly mobileItems?: T[]
    /**
     * If the Menu Items are to be rendered as Header Items,
     * so they are visually identical (used in mobile menu).
     */
    readonly mobileExpand?: boolean
}

/**
 * A Header Item subtype that ends up being custom rendered,
 * such as a search bar, status icons, action buttons, etc.
 */
export type CustomHeaderItem = true

export type HeaderItem = {
    [P in keyof HeaderPartVariant]: HeaderPartVariant[P]
}

export type HeaderItemName = keyof HeaderLeftVariant | keyof HeaderRightVariant

// HEADER VARIANTS > TYPES

export type HeaderLeftVariant = {
    readonly jobs?: Menu<JobsMenuItemName> | NavLink
    readonly companies?: Menu<CompaniesMenuItemName> | NavLink
    readonly articles?: Menu<ArticlesMenuItemName> | NavLink
    readonly candidates?: Menu<CandidatesMenuItemName>
    readonly communities?: Menu<CommunitiesMenuItemName>
}

export type HeaderRightVariant = {
    readonly search?: CustomHeaderItem
    readonly messages?: CustomHeaderItem
    readonly applications?: CustomHeaderItem
    readonly employer?: Menu<EmployerMenuItemName>
    readonly user?: Menu<UserMenuItemName>
    readonly guestCTA?: CustomHeaderItem
}

type HeaderPartVariant = HeaderLeftVariant | HeaderRightVariant

export type HeaderVariant = {
    /**
     * The variant of the `LeftSide` of the `Header`.
     */
    readonly left: HeaderLeftVariant
    /**
     * The variant of the `RightSide` of the `Header`.
     */
    readonly right: HeaderRightVariant
}

// HEADER VARIANTS > DESCRIPTORS

/**
 * This data structure serves as a descriptor for the `Header`.
 *
 * The latter itself is merely a universal rendering engine which uses
 * the types (defined in this module) as a contract for the former one.
 *
 * For all 4 possible `Header` variants it determines:
 * - how the Header Items will get rendered (links, menus, or custom)
 * - order of the top-level Header Items & menus items
 * - the navigation links labels and URLs
 */
const headerVariants: Record<UserTypeExt, HeaderVariant> = {
    [Guest]: {
        left: {
            jobs: {
                label: 'Jobs',
                url: routes.jobs(),
            },
            articles: {
                label: 'Articles',
                url: routes.articles(),
            },
            companies: {
                label: 'Companies',
                url: routes.companies(),
            },
            communities: {
                label: 'Our Communities',
                items: communityVerticals,
            },
        },
        right: {
            search: true,
            employer: {
                label: 'For Employers',
                items: [
                    'WhyWorksHub',
                    'PostFirstJob',
                    'PricingAndPlans',
                    'SignUpCompany',
                    'Login',
                ],
            },
            guestCTA: true,
        },
    },
    [UserType.Candidate]: {
        left: {
            jobs: {
                label: 'Jobs',
                items: ['Jobsboard', 'AppliedJobs', 'SavedJobs', 'RecommendedJobs'],
            },
            articles: {
                label: 'Articles',
                items: ['AllArticles', 'WriteArticle', 'SavedArticles'],
            },
            companies: {
                label: 'Companies',
                url: routes.companies(),
            },
            communities: {
                label: 'Our Communities',
                items: communityVerticals,
            },
        },
        right: {
            search: true,
            messages: true,
            user: {
                items: ['ProfileSettings', 'NotificationSettings', 'Logout'],
                mobileItems: [
                    'Divider',
                    'UserSnippet',
                    'ProfileSettings',
                    'NotificationSettings',
                    'Logout',
                ],
                mobileExpand: true,
            },
        },
    },
    [UserType.Company]: {
        left: {
            jobs: {
                label: 'Jobs',
                items: ['Jobsboard', 'PostNewJob', 'AllLiveApplications'],
            },
            companies: {
                label: 'Company',
                items: ['CompanyProfile', 'PublicCompanies'],
            },
            articles: {
                label: 'Articles',
                items: ['AllArticles', 'WriteArticle', 'SavedArticles'],
            },
        },
        right: {
            search: true,
            messages: true,
            applications: true,
            user: {
                items: [
                    'CompanyProfile',
                    'CompanySettings',
                    'ProfileSettings',
                    'Feed',
                    'Logout',
                ],
                mobileItems: [
                    'Divider',
                    'CompanySnippet',
                    'CompanySettings',
                    'Divider',
                    'UserSnippet',
                    'ProfileSettings',
                    'NotificationSettings',
                    'Feed',
                    'Logout',
                ],
                mobileExpand: true,
            },
        },
    },
    [UserType.Admin]: {
        left: {
            jobs: {
                label: 'Jobs',
                items: [
                    'Jobsboard',
                    'AllLiveApplications',
                    'PostNewJob',
                    'AppliedJobs',
                    'SavedJobs',
                    'RecommendedJobs',
                    'JobsForReview',
                ],
            },
            companies: {
                label: 'Companies',
                items: ['AllCompanies', 'PublicCompanies', 'CreateCompany'],
            },
            articles: {
                label: 'Articles',
                items: [
                    'AllArticles',
                    'WriteArticle',
                    'SavedArticles',
                    'UnpublishedArticles',
                ],
            },
            candidates: {
                label: 'Candidates',
                items: ['AllCandidates', 'CreateCandidate'],
            },
            communities: {
                label: 'Our Communities',
                items: communityVerticals,
            },
        },
        right: {
            search: true,
            messages: true,
            applications: true,
            user: {
                items: [
                    'ProfileSettings',
                    'NotificationSettings',
                    'Metrics',
                    'Feed',
                    'Logout',
                ],
                mobileItems: [
                    'Divider',
                    'UserSnippet',
                    'ProfileSettings',
                    'NotificationSettings',
                    'Metrics',
                    'Feed',
                    'Logout',
                ],
                mobileExpand: true,
            },
        },
    },
}

// AUXILIARY FUNCTIONS

/**
 * Retrieves user type-specific descriptor for the `Header` variant.
 *
 * @param currentUser — current app user
 */
export function getHeaderVariant(currentUser: Me) {
    return headerVariants[getUserTypeExt(currentUser)]
}

/**
 * Retrieves a (sub)list of `Header` items for a specific app user.
 *
 * @param currentUser — current app user
 * @param part — if either 'left' or 'right', returns Header Items for that part;
 *               if unspecified, return all Header Items, in left-to-right order
 */
export function listHeaderItems(currentUser: Me, part?: 'left' | 'right') {
    const headerVariant = getHeaderVariant(currentUser)

    if (part === 'left') {
        return Object.entries(headerVariant.left) as [HeaderItemName, HeaderItem][]
    }
    if (part === 'right') {
        return Object.entries(headerVariant.right) as [HeaderItemName, HeaderItem][]
    }

    return [
        ...Object.entries(headerVariant.left),
        ...Object.entries(headerVariant.right),
    ] as [HeaderItemName, HeaderItem][]
}

// NB: Type predicates for all `HeaderItem` subtypes, for setting user-defined type guard.
//     https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates

export function isNavLink(item: HeaderItem): item is NavLink {
    return (item as NavLink).url !== undefined
}

export function isMenu(item: HeaderItem): item is Menu<MenuItemName> {
    return (item as Menu<MenuItemName>).items !== undefined
}

export function isCustom(item: HeaderItem): item is CustomHeaderItem {
    return item === true
}

/**
 * Retrieves a list of `MenuItemName` for a specific `menu`.
 * The list may be different for desktop and mobile headers.
 *
 * @param menu — a `Menu` which items to retrieve
 * @param forMobile — an indicator for mobile version
 */
export function getMenuItemsNames<T extends MenuItemName>(
    menu: Menu<T>,
    forMobile?: boolean,
): T[] {
    return forMobile ? menu.mobileItems ?? menu.items : menu.items
}

/**
 * Retrieves a `MenuItem` descriptor by its `name`.
 *
 * @param name — a `MenuItemName` to retrieve
 */
export function getMenuItem(name: MenuItemName): MenuItem {
    const menuItem =
        JobsMenuItems[name as JobsMenuItemName] ||
        CompaniesMenuItems[name as CompaniesMenuItemName] ||
        ArticlesMenuItems[name as ArticlesMenuItemName] ||
        CandidatesMenuItems[name as CandidatesMenuItemName] ||
        CommunitiesMenuItems[name as CommunitiesMenuItemName] ||
        EmployerMenuItems[name as EmployerMenuItemName] ||
        UserMenuItems[name as UserMenuItemName]

    if (!menuItem) {
        throw new Error('Not implemented! Add the case for new menu')
    }
    return menuItem
}
