import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import useMe from 'hooks/useMe'
import { FancyLabel, Gap } from 'components/appShell/header/components/FancyLabel'
import {
    IconInfo,
    getHeaderItemIconInfo,
    getMenuItemIconInfo,
} from 'components/appShell/header/components/Icons'
import { HeaderItemName, MenuItemName } from 'components/appShell/header/structure'
import { doRenderHeaderItemsIcons } from 'components/appShell/header/utils'

// NB: Even though navigation links rendering is different,
//     a set of the common component properties is the same.
export type NavLinksProps = {
    label: string
    url: string
    tag?: string
    gap?: Gap
    active?: boolean
    forMobile?: boolean
    onClick?: () => void
}

export const NavLink = ({
    name,
    iconInfo,
    label,
    url,
    tag,
    gap,
    active = false,
    forMobile = false,
    onClick,
}: {
    name: HeaderItemName
    iconInfo?: IconInfo
} & NavLinksProps) => {
    const currentUser = useMe()

    const iconInfoToUse = doRenderHeaderItemsIcons(label, forMobile)
        ? iconInfo ?? getHeaderItemIconInfo(name, currentUser)
        : undefined

    return (
        <Link href={url} passHref>
            <ChakraLink
                variant="header"
                display="flex"
                // NB: On mobile, display a wider touch area (width + padding).
                width={forMobile ? '100%' : 'auto'}
                height="100%"
                py={forMobile ? '14px' : 0}
                data-is-active={active}
                data-is-mobile={forMobile}
                data-svg-hover-strategy={iconInfoToUse?.svgHoverStrategy ?? 'stroke'}
                onClick={onClick}
                tabIndex={active ? -1 : 0}
            >
                <FancyLabel
                    text={label}
                    icon={iconInfoToUse?.icon}
                    tag={tag}
                    gap={gap}
                    size={forMobile ? 'md' : 'sm'}
                />
            </ChakraLink>
        </Link>
    )
}

export const NavMenuItemLink = ({
    headerItemName,
    menuItemName,
    expanded,
    label,
    url,
    tag,
    gap = 'md',
    active = false,
    forMobile = false,
    onClick,
}: {
    headerItemName: HeaderItemName
    menuItemName: MenuItemName
    expanded?: boolean
} & NavLinksProps) => {
    const currentUser = useMe()

    const iconInfo = getMenuItemIconInfo(
        menuItemName,
        expanded ? '24px' : '16px',
        currentUser,
    )

    // NB: Expansion is used at mobile menu (see `mobileExpanded`)
    //     and boils down to rendering menu items as header items,
    //     so they are visually identical.
    if (expanded) {
        return (
            <NavLink
                name={headerItemName}
                iconInfo={iconInfo}
                label={label}
                url={url}
                tag={tag}
                gap={gap}
                active={active}
                forMobile={forMobile}
                onClick={onClick}
            />
        )
    } else {
        return (
            <Link href={url} passHref>
                <ChakraLink
                    variant="menuItem"
                    width="100%"
                    // NB: On mobile, display a wider touch area (width + padding).
                    py={forMobile ? '14px' : 0}
                    _first={{ pt: 0 }}
                    _last={{ pb: 0 }}
                    data-is-active={active}
                    data-svg-hover-strategy={iconInfo?.svgHoverStrategy ?? 'stroke'}
                    onClick={onClick}
                    tabIndex={active ? -1 : 0}
                >
                    <FancyLabel
                        text={label}
                        icon={iconInfo?.icon}
                        tag={tag}
                        gap={gap}
                        size="sm"
                    />
                </ChakraLink>
            </Link>
        )
    }
}
