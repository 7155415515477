import React from 'react'
import { Flex } from '@chakra-ui/react'
import { routes } from 'routes'
import { ButtonLink } from 'components/button/ButtonLink'

export const GuestCTA = () => {
    return (
        <Flex alignItems="center" gridGap="8px">
            <ButtonLink
                label="Log in"
                linkProps={{
                    href: routes.login(),
                }}
                display={{ base: 'none', xl: 'block' }}
                variant="secondary"
                colorScheme="primary"
                fontWeight={700}
            />
            <ButtonLink
                label="Sign up"
                linkProps={{
                    href: routes.register(),
                }}
                display={{ base: 'none', xl: 'block' }}
                variant="primary"
                fontWeight={700}
            />
            <ButtonLink
                label="Get started"
                linkProps={{
                    href: routes.register(),
                }}
                display={{ base: 'block', xl: 'none' }}
                variant="primary"
                fontWeight={700}
            />
        </Flex>
    )
}
