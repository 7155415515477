import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { isGuest } from 'entities/user'
import useHeaderSizes from 'hooks/useHeaderSizes'
import useMe from 'hooks/useMe'
import { BurgerMenu } from 'components/appShell/header/components/BurgerMenu'
import { DynamicLogo } from 'components/appShell/header/components/DynamicLogo'
import {
    HeaderItem,
    HeaderItemMobile,
} from 'components/appShell/header/components/items/HeaderItems'
import { listHeaderItems } from 'components/appShell/header/structure'

/**
 * To keep the related mobile-only components `display` props in sync.
 */
const DISPLAY_FOR_MOBILE_ONLY_COMPONENTS = { base: 'flex', lg: 'none' }

/**
 * The main parent component for a variable App Shell header.
 */
export const Header = ({
    isMenuOpen,
    onMenuOpen,
    onMenuClose,
}: {
    isMenuOpen: boolean
    onMenuOpen: () => void
    onMenuClose: () => void
}) => {
    const headerSizes = useHeaderSizes()

    return (
        <Box
            gridArea="header"
            position="sticky"
            top={0}
            zIndex="sticky"
            maxWidth="100vw"
            height={isMenuOpen ? 'fit-content' : undefined}
            minHeight={isMenuOpen ? '100vh' : '0'}
            backgroundColor="white"
            borderBottomWidth="1px"
            borderBottomColor="gray.dividers"
            borderBottomStyle="solid"
        >
            <Flex
                height={headerSizes}
                alignItems="stretch"
                justifyContent="space-between"
                gridGap={{
                    base: '14px',
                    xs: '20px',
                    md: '24px',
                    lg: '32px',
                }}
                maxWidth="2000px" // $global-max-width
                mx="auto"
                px={{
                    base: '16px',
                    xs: '20px',
                    md: '24px',
                }}
            >
                <DynamicLogo />
                <LeftSide />
                <RightSide
                    isMenuOpen={isMenuOpen}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                />
            </Flex>
            {isMenuOpen && <OverlayMenu />}
        </Box>
    )
}

/**
 * Logotype + main navigation header items (links and menus).
 */
const LeftSide = () => {
    const currentUser = useMe()

    return (
        <Flex
            as="nav"
            display={{ base: 'none', lg: 'flex' }}
            overflowY="hidden"
            flexWrap="wrap"
            gridGap="32px"
        >
            {listHeaderItems(currentUser, 'left').map(([name, item]) => {
                return <HeaderItem key={name} name={name} item={item} />
            })}
        </Flex>
    )
}

/**
 * App-specific header items (status icons, user menu, etc.).
 */
const RightSide = ({
    isMenuOpen,
    onMenuOpen,
    onMenuClose,
}: {
    isMenuOpen: boolean
    onMenuOpen: () => void
    onMenuClose: () => void
}) => {
    const currentUser = useMe()

    return (
        <>
            {/* NB: The adaptation to the width is done on a header item level. */}
            <Flex
                as="nav"
                ml="auto"
                gridGap={isGuest(currentUser) ? '28px' : '10px'}
                alignItems="center"
            >
                {listHeaderItems(currentUser, 'right').map(([name, item]) => {
                    return <HeaderItem key={name} name={name} item={item} />
                })}
            </Flex>
            <Box display={DISPLAY_FOR_MOBILE_ONLY_COMPONENTS}>
                <BurgerMenu
                    isMenuOpen={isMenuOpen}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                />
            </Box>
        </>
    )
}

/**
 * The mobile-only overlay menu that stacks all header items.
 */
const OverlayMenu = () => {
    const currentUser = useMe()

    return (
        <Flex
            display={DISPLAY_FOR_MOBILE_ONLY_COMPONENTS}
            as="nav"
            direction="column"
            alignItems="flex-start"
            my="28px"
            mx="24px"
        >
            {listHeaderItems(currentUser).map(([name, item]) => {
                return <HeaderItemMobile key={name} name={name} item={item} />
            })}
        </Flex>
    )
}
