import React, { ReactNode } from 'react'
import { BackgroundProps, BorderProps, Box, Grid, useDisclosure } from '@chakra-ui/react'
import { LiveUpdatesProvider } from 'hooks/useLiveUpdates'
import useMe from 'hooks/useMe'
import { useVertical } from 'hooks/useVerticalData'
import { Promo } from 'components/Promo'
import { Head as AppShellHead } from 'components/appShell/Head'
import { Footer } from 'components/appShell/footer/Footer'
import { Header } from 'components/appShell/header/Header'

const WithLiveUpdatesProvider = ({ children }: { children: ReactNode }) => {
    const me = useMe()

    if (me) {
        return <LiveUpdatesProvider>{children}</LiveUpdatesProvider>
    }
    return <>{children}</>
}

export default function AppShell({
    children,
    title,
    footer = true,
    contentMaxWidth,
    contentMinHeight,
    backgroundColor = 'white',
    borderColor = 'transparent',
}: {
    children: ReactNode
    title: string
    footer?: boolean
    contentMaxWidth?: string
    contentMinHeight?: string
} & Pick<BackgroundProps, 'backgroundColor'> &
    Pick<BorderProps, 'borderColor'>) {
    const {
        isOpen: isMenuOpen,
        onOpen: onMenuOpen,
        onClose: onMenuClose,
    } = useDisclosure()
    const vertical = useVertical()

    return (
        <WithLiveUpdatesProvider>
            <>
                <Promo />
                <AppShellHead title={title} vertical={vertical} />
                <Grid
                    templateRows="auto 1fr"
                    templateColumns={
                        contentMaxWidth
                            ? `1fr minmax(auto, ${contentMaxWidth}) 1fr`
                            : undefined
                    }
                    templateAreas={
                        contentMaxWidth
                            ? `"header header  header"
                       "l-void content r-void"
                       "footer footer  footer"`
                            : `"header"
                       "content"
                       "footer"`
                    }
                    minHeight={isMenuOpen ? '100vh' : contentMinHeight}
                    backgroundColor={backgroundColor}
                >
                    <Header
                        isMenuOpen={isMenuOpen}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuClose}
                    />
                    {!isMenuOpen && (
                        <Box gridArea="content" borderColor={borderColor} maxW="100vw">
                            {children}
                        </Box>
                    )}
                </Grid>
                {footer && <Footer />}
            </>
        </WithLiveUpdatesProvider>
    )
}
