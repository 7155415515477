import React from 'react'
import { Badge } from '@chakra-ui/react'

export const BadgeNav = ({ children }: { children: number }) => {
    return (
        <Badge
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="1px"
            left="10px"
            transform="translateX(-50%)"
            minWidth="16px"
            p="4px 5px"
            borderRadius="8px"
            fontWeight={600}
            fontSize="10px"
            lineHeight="8px"
            letterSpacing="-0.01em"
            color="white"
            backgroundColor="primary.500"
        >
            {children}
        </Badge>
    )
}
