import React, { useState } from 'react'
import {
    Box,
    IconButton,
    Input,
    InputGroup,
    InputGroupProps,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import { useRouter } from 'next/router'
import { X as Close, Search } from '@emotion-icons/feather'
import { routes } from 'routes'

const SearchIcon = chakra(Search)
const CloseIcon = chakra(Close)

export const SearchBar = ({
    forMobile = false,
    ...inputGroupProps
}: { forMobile?: boolean } & InputGroupProps) => {
    const router = useRouter()
    const [searchTerm, setSearchTerm] = useState<string>('')

    const isSearchTermPresent = searchTerm.trim().length > 0

    return (
        <Box
            display={
                forMobile
                    ? { base: 'contents', md: 'none' }
                    : { base: 'none', md: 'contents' }
            }
            as="form"
            // @ts-ignore
            onSubmit={e => {
                e.preventDefault()
                router.push(routes.search(searchTerm))
            }}
        >
            <InputGroup
                mr={forMobile ? undefined : { base: '6px', md: '8px' }}
                {...inputGroupProps}
            >
                <InputLeftElement width="36px" pointerEvents="none">
                    <SearchIcon boxSize="22px" strokeWidth={1.5} />
                </InputLeftElement>
                <Input
                    type="text"
                    variant={forMobile ? 'filled' : 'outline'}
                    colorScheme="gray"
                    size="custom"
                    height="40px"
                    minWidth="120px"
                    maxWidth={forMobile ? undefined : '192px'}
                    p="0 30px 0 36px !important"
                    borderRadius="4px"
                    borderColor="gray.dividers"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    placeholder="Search..."
                    _focus={{
                        outline: 'none',
                    }}
                    value={searchTerm}
                    onChange={e => {
                        setSearchTerm(e.target?.value)
                    }}
                />
                <InputRightElement display={isSearchTermPresent ? undefined : 'none'}>
                    <IconButton
                        title="Clear the search term"
                        aria-label="Clear the search term"
                        type="reset"
                        variant="unstyled"
                        colorScheme="primary"
                        onClick={() => {
                            setSearchTerm('')
                        }}
                        icon={<CloseIcon boxSize="18px" strokeWidth={1.5} />}
                    />
                </InputRightElement>
            </InputGroup>
        </Box>
    )
}
