import React from 'react'
import { Me } from 'entities/user'
import { App } from 'lib/app'
import { VerticalData } from 'hooks/useVerticalData'
import { NavLinksProps } from 'components/appShell/header/components/items/NavLinks'
import {
    HeaderItemName,
    MenuItem,
    MenuItemName,
} from 'components/appShell/header/structure'
import { getUserSpecificPropValue } from 'components/appShell/header/utils'

type MenuItemProps = Partial<NavLinksProps> | undefined

function getCustomMenuItemProps(
    headerItemName: HeaderItemName,
    menuItemName: MenuItemName,
    verticalData: VerticalData,
): MenuItemProps {
    if (headerItemName === 'communities') {
        // a smaller gap between icon & label + `active` and URL props for link
        return {
            gap: 'sm',
            active: menuItemName === verticalData.vertical,
            url: verticalData.allVerticalUrls[verticalData.vertical],
        }
    }

    if (headerItemName === 'user') {
        if (menuItemName === 'UserSnippet' || menuItemName === 'CompanySnippet') {
            // a larger gap between icon & label
            return {
                gap: 'lg',
            }
        }
        if (menuItemName === 'Logout') {
            // an `onClick` event handler for link
            return {
                onClick: () => App.logout(),
            }
        }
    }
}

export function getMenuItemProps(
    headerItemName: HeaderItemName,
    menuItemName: MenuItemName,
    menuItem: MenuItem,
    currentUser: Me,
    verticalData: VerticalData,
) {
    const label = getUserSpecificPropValue(menuItem.label, currentUser)
    const url = getUserSpecificPropValue(menuItem.url, currentUser)
    const tag = menuItem.tag

    const customProps = getCustomMenuItemProps(headerItemName, menuItemName, verticalData)

    return {
        headerItemName,
        menuItemName,
        label,
        url,
        tag,
        ...customProps,
    }
}
