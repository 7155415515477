import { Me } from 'entities/user'

/**
 * Used for user type-dependent labels & URLs within the `Header` structure.
 */
export type UserSpecificProp = string | ((currentUser: Me) => string)

export function getUserSpecificPropValue(prop: UserSpecificProp, currentUser: Me) {
    if (typeof prop === 'function') {
        return prop(currentUser)
    }
    return prop
}

/**
 * Determines whether or not to render an icon for the `Header` item (for both
 * links & menus). Won't affect an item without a text `label`.
 */
export function doRenderHeaderItemsIcons(label?: string, forMobile?: boolean) {
    return !label || !!forMobile
}
