import React, { ReactNode } from 'react'
import {
    Box,
    Link as ChakraLink,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useDisclosure,
} from '@chakra-ui/react'
import { ResponsiveObject, chakra } from '@chakra-ui/system'
import * as CSS from 'csstype'
import { slugify } from 'lib/text'
import useMe from 'hooks/useMe'
import { FancyLabel } from 'components/appShell/header/components/FancyLabel'
import { getHeaderItemIconInfo } from 'components/appShell/header/components/Icons'
import { HeaderItemName } from 'components/appShell/header/structure'
import { doRenderHeaderItemsIcons } from 'components/appShell/header/utils'
import SmallTriangle from 'public/img/small_triangle.svg'

const SmallTriangleChakra = chakra(SmallTriangle)

type Element = 'wrapper' | 'triangle'
export type ShowSettings = Partial<Record<Element, ResponsiveObject<boolean>>>

const transformToDisplayProp = (
    obj: ResponsiveObject<boolean> | undefined,
    showDisplayValue: string,
) => {
    if (!obj) {
        return showDisplayValue
    }
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] = value ? showDisplayValue : 'none'
        return acc
    }, {} as ResponsiveObject<CSS.Property.Display>)
}

export const MenuPopover = ({
    name,
    label,
    doShow,
    children,
}: {
    name: HeaderItemName
    label?: string
    doShow?: ShowSettings
    children: ReactNode
}) => {
    const currentUser = useMe()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const wrapperDisplay = transformToDisplayProp(doShow?.wrapper, 'flex')
    const triangleDisplay = transformToDisplayProp(doShow?.triangle, 'contents')

    const iconInfo = doRenderHeaderItemsIcons(label)
        ? getHeaderItemIconInfo(name, currentUser)
        : undefined

    return (
        <Popover
            id={`${slugify(name)}-menu`}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            placement="bottom-start"
            gutter={0}
            trigger="hover"
        >
            <PopoverTrigger>
                <Box
                    display={wrapperDisplay}
                    as={ChakraLink}
                    variant="header"
                    justifyContent="center"
                    alignItems="center"
                    gridGap="6px"
                    height="100%"
                    data-svg-hover-strategy={iconInfo?.svgHoverStrategy ?? 'stroke'}
                    data-is-active={isOpen}
                    tabIndex={0}
                >
                    <FancyLabel text={label} icon={iconInfo?.icon} size="sm" />
                    <Box display={triangleDisplay} data-svg-hover-strategy="none">
                        <SmallTriangleChakra height="5px" width="8px" />
                    </Box>
                </Box>
            </PopoverTrigger>
            <PopoverContent
                width="fit-content"
                minWidth="248px"
                p="26px"
                // affect position
                ml="-16px"
                mr="-16px"
                border="none"
                borderRadius="14px"
                boxShadow="menu"
                _focus={{ outline: 'none' }}
            >
                {children}
            </PopoverContent>
        </Popover>
    )
}
