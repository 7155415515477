import React, { ReactNode } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

export type Gap = 'sm' | 'md' | 'lg'
export type Size = 'sm' | 'md'

const LinkTag = ({ label }: { label: string }) => {
    return (
        <Box
            height="20px"
            px="6px"
            borderRadius="4px"
            fontWeight="500"
            fontSize="12px"
            lineHeight="20px"
            color="secondary.text"
            backgroundColor="secondary.light"
        >
            {label}
        </Box>
    )
}

// Specifies the relative tag position
const SHIFT_TAG_X = '4px'
const SHIFT_TAG_Y = '10px'

// Determines the label gutters
const GAPS = {
    sm: '6px',
    md: '10px',
    lg: '15px',
}

/**
 * This kind of "label" that knows their job too well.
 * It universally combines a text, an icon, and a tag.
 *
 * Allows for some minimal layout & typography tuning.
 */
export const FancyLabel = ({
    text,
    tag,
    icon,
    gap = 'md',
    size = 'md',
}: {
    text?: string
    icon?: ReactNode
    tag?: string
    gap?: Gap
    size?: Size
}) => {
    const gridGap = GAPS[gap]

    return (
        <Flex gridGap={gridGap} alignItems="center">
            {icon}
            {text && (
                <Text
                    color="inherit"
                    fontWeight={size === 'md' ? '600' : '500'}
                    fontSize={size === 'md' ? '16px' : '14px'}
                    lineHeight={size === 'md' ? '24px' : '20px'}
                    whiteSpace="nowrap"
                >
                    {text}
                </Text>
            )}
            {tag && (
                <Box
                    mx={`calc(${SHIFT_TAG_X} - ${gridGap})`}
                    mt={`calc(-2 * ${SHIFT_TAG_Y})`}
                >
                    <LinkTag label={tag} />
                </Box>
            )}
        </Flex>
    )
}
