import { Icon, IconProps } from '@chakra-ui/react'
import { StandardVertical } from 'entities/vertical'
import AILogo from 'public/img/verticals/ai_logo.svg'
import BlockchainLogo from 'public/img/verticals/blockchain_logo.svg'
import FunctionalLogo from 'public/img/verticals/functional_logo.svg'
import GolangLogo from 'public/img/verticals/golang_logo.svg'
import JavaLogo from 'public/img/verticals/java_logo.svg'
import JavaScriptLogo from 'public/img/verticals/javascript_logo.svg'
import PythonLogo from 'public/img/verticals/python_logo.svg'
import RemoteLogo from 'public/img/verticals/remote_logo.svg'
import CompanyLogo from 'public/img/verticals/www_logo.svg'

export const VerticalLogo = ({
    vertical,
    ...iconProps
}: {
    vertical?: StandardVertical
} & IconProps) => {
    switch (vertical) {
        case 'functional':
            return <Icon as={FunctionalLogo} {...iconProps} />
        case 'blockchain':
            return <Icon as={BlockchainLogo} {...iconProps} />
        case 'javascript':
            return <Icon as={JavaScriptLogo} {...iconProps} />
        case 'ai':
            return <Icon as={AILogo} {...iconProps} />
        case 'golang':
            return <Icon as={GolangLogo} {...iconProps} />
        case 'java':
            return <Icon as={JavaLogo} {...iconProps} />
        case 'python':
            return <Icon as={PythonLogo} {...iconProps} />
        case 'remote':
            return <Icon as={RemoteLogo} {...iconProps} />
        default:
            return <Icon as={CompanyLogo} {...iconProps} />
    }
}
