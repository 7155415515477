import React from 'react'
import NextHead from 'next/head'
import { StandardVertical } from 'entities/vertical'
import { routes } from 'routes'

export const Head = ({
    title,
    vertical,
}: {
    title: string
    vertical: StandardVertical
}) => {
    return (
        <NextHead>
            <title>{title}</title>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <link
                rel="icon"
                type="image/x-icon"
                href={routes.resource.favicon(vertical)}
            />
        </NextHead>
    )
}
