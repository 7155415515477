import { useMemo } from 'react'
import { useTheme } from '@chakra-ui/react'
import { useWindowSize } from 'usehooks-ts'
import { isServerSide } from 'lib/env'

// DANGER: This hook is not SSR safe.
// you shouldn't pass false to this hook
// false is passed in legacy places where we want to remove this hook
function useIsMobile(shouldThrow = true) {
    const { width } = useWindowSize()
    const { breakpoints } = useTheme()

    if (isServerSide && shouldThrow) {
        throw new Error('useIsMobile should not be used on server side')
    }

    return useMemo(() => {
        return width < parseInt(breakpoints.md)
    }, [width, breakpoints.md])
}

export default useIsMobile
