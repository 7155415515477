import { useTheme } from '@chakra-ui/react'

/**
 * Extracts sizes for the App Shell Header from the Chakra theme.
 */
const useHeaderSizes = () => {
    const theme: any = useTheme()
    return theme.variables.header as { base: string; md: string }
}

export default useHeaderSizes
