import React, { ReactNode } from 'react'
import { chakra } from '@chakra-ui/system'
import {
    Archive as ArchiveIcon,
    ArrowUpRight as ArrowUpRightIcon,
    Bell as BellIcon,
    Bookmark as BookmarkIcon,
    Briefcase as BriefcaseIcon,
    CheckCircle as CheckCircleIcon,
    File as FileIcon,
    FileText as FileTextIcon,
    Grid as GridIcon,
    LogIn as LogInIcon,
    PlusCircle as PlusCircleIcon,
    Rss as RssIcon,
    Search as SearchIcon,
    Settings as SettingsIcon,
    User as UserIcon,
} from '@emotion-icons/feather'
import { Payment as PaymentIcon } from '@emotion-icons/fluentui-system-regular'
import { Me } from 'entities/user'
import { verticalOptions } from 'lib/vertical'
import useIsMobile from 'hooks/useIsMobile'
import { VerticalLogo } from 'components/VerticalLogo'
import { HeaderItemName, MenuItemName } from 'components/appShell/header/structure'
import { Avatar } from 'components/images/Avatar'
import { SvgHoverStrategy } from 'theme/svgHover'
import BuildingsIcon from 'public/img/buildings_icon.svg'
import CommunitiesIcon from 'public/img/communities_icon.svg'
import FactoryIcon from 'public/img/factory_icon.svg'
import LeaveIcon from 'public/img/leave_icon.svg'
import RecommendedIcon from 'public/img/recommended_icon.svg'
import SpacecraftIcon from 'public/img/spacecraft_icon.svg'
import UserGroupIcon from 'public/img/user_group_icon.svg'
import WorksHubIcon from 'public/img/wh_icon.svg'

const GridIconChakra = chakra(GridIcon)
const FileIconChakra = chakra(FileIcon)
const BookmarkIconChakra = chakra(BookmarkIcon)
const RecommendedIconChakra = chakra(RecommendedIcon)
const PlusCircleIconChakra = chakra(PlusCircleIcon)
const FileTextIconChakra = chakra(FileTextIcon)
const SearchIconChakra = chakra(SearchIcon)
const BuildingsIconChakra = chakra(BuildingsIcon)
const FactoryIconChakra = chakra(FactoryIcon)
const ArchiveIconChakra = chakra(ArchiveIcon)
const UserGroupIconChakra = chakra(UserGroupIcon)
const WorksHubIconChakra = chakra(WorksHubIcon)
const PaymentIconChakra = chakra(PaymentIcon)
const CheckCircleIconChakra = chakra(CheckCircleIcon)
const LogInIconChakra = chakra(LogInIcon)
const UserIconChakra = chakra(UserIcon)
const BellIconChakra = chakra(BellIcon)
const SettingsIconChakra = chakra(SettingsIcon)
const RssIconChakra = chakra(RssIcon)
const SpacecraftIconChakra = chakra(SpacecraftIcon)
const LeaveIconChakra = chakra(LeaveIcon)
const BriefcaseIconChakra = chakra(BriefcaseIcon)
const CommunitiesIconChakra = chakra(CommunitiesIcon)
const ArrowUpRightIconChakra = chakra(ArrowUpRightIcon)

const AvatarForUser = ({ currentUser }: { currentUser: Me }) => {
    const isMobile = useIsMobile(false)
    const src = currentUser?.isCompanyUser
        ? currentUser.company!.logo
        : currentUser?.imageUrl

    return (
        <Avatar
            variant={currentUser?.isCompanyUser ? 'company' : 'user'}
            src={src}
            boxSize={isMobile ? '40px' : '48px'}
            ml={{ base: '6px', md: '8px' }}
        />
    )
}

export type IconInfo = {
    icon: ReactNode
    svgHoverStrategy?: SvgHoverStrategy
}

export function getHeaderItemIconInfo(
    name: HeaderItemName,
    currentUser: Me,
): IconInfo | undefined {
    // NB: For mobile menu labels to be aligned, the icons must take the exact space (24px).
    switch (name) {
        case 'jobs':
            return {
                icon: (
                    <BriefcaseIconChakra width="22px" ml="1px" mr="1px" strokeWidth={2} />
                ),
            }
        case 'companies':
            return {
                icon: (
                    <FactoryIconChakra width="20px" ml="2px" mr="2px" strokeWidth={0} />
                ),
            }
        case 'articles':
            return {
                icon: <FileIconChakra width="21px" ml="2px" mr="1px" strokeWidth={2} />,
            }
        case 'communities':
            return {
                icon: (
                    <CommunitiesIconChakra
                        width="22px"
                        ml="1px"
                        mr="1px"
                        strokeWidth={1}
                    />
                ),
            }
        case 'candidates':
            return {
                icon: (
                    <UserGroupIconChakra width="19px" ml="3px" mr="2px" strokeWidth={1} />
                ),
            }
        case 'employer':
            return {
                icon: <ArrowUpRightIconChakra width="24px" strokeWidth={2} />,
            }
        case 'user':
            return {
                icon: <AvatarForUser currentUser={currentUser} />,
            }
    }
}

export function getMenuItemIconInfo(
    name: MenuItemName,
    width: string,
    currentUser: Me,
): IconInfo | undefined {
    // NB: The idea behind using a `switch` statement is simply to maintain
    //     consistency between the same items sitting under different menus.
    //     Thereby, if a menu item doesn't render the way you expect it to,
    //     it's most likely a name collision in the `Header` structure.
    switch (name) {
        // jobs
        case 'Jobsboard':
            return {
                icon: <GridIconChakra width={width} strokeWidth={2} />,
            }
        case 'AppliedJobs':
            return {
                icon: <FileIconChakra width={width} strokeWidth={2} />,
            }
        case 'SavedJobs':
            return {
                icon: <BookmarkIconChakra width={width} strokeWidth={2} />,
            }
        case 'RecommendedJobs':
            return {
                icon: <RecommendedIconChakra width={width} mt="-3px" strokeWidth={1} />,
            }
        case 'PostNewJob':
            return {
                icon: <PlusCircleIconChakra width={width} strokeWidth={2} />,
            }
        case 'AllLiveApplications':
        case 'JobsForReview':
            return {
                icon: <FileTextIconChakra width={width} strokeWidth={2} />,
            }

        // companies
        case 'AllCompanies':
            return {
                icon: <SearchIconChakra width={width} strokeWidth={2} />,
            }
        case 'PublicCompanies':
            return {
                icon: <BuildingsIconChakra width={width} strokeWidth={1} />,
                svgHoverStrategy: 'fill',
            }
        case 'CreateCompany':
            return {
                icon: <PlusCircleIconChakra width={width} strokeWidth={2} />,
            }
        case 'CompanyProfile':
            return {
                icon: <FactoryIconChakra width={width} strokeWidth={1} />,
                svgHoverStrategy: 'fill',
            }

        // articles
        case 'AllArticles':
            return {
                icon: <FileIconChakra width={width} strokeWidth={2} />,
            }
        case 'WriteArticle':
            return {
                icon: <PlusCircleIconChakra width={width} strokeWidth={2} />,
            }
        case 'SavedArticles':
            return {
                icon: <BookmarkIconChakra width={width} strokeWidth={2} />,
            }
        case 'UnpublishedArticles':
            return {
                icon: <ArchiveIconChakra width={width} strokeWidth={2} />,
            }

        // candidates
        case 'AllCandidates':
            return {
                icon: <UserGroupIconChakra width={width} strokeWidth={1} />,
            }
        case 'CreateCandidate':
            return {
                icon: <PlusCircleIconChakra width={width} strokeWidth={2} />,
            }

        // for employers
        case 'WhyWorksHub':
            return {
                icon: (
                    <WorksHubIconChakra
                        width={width}
                        transform="scale(1.25)" // too small otherwise
                        strokeWidth={1}
                    />
                ),
            }
        case 'PostFirstJob':
            return {
                icon: <PlusCircleIconChakra width={width} strokeWidth={2} />,
            }
        case 'PricingAndPlans':
            return {
                icon: <PaymentIconChakra width={width} strokeWidth={1} />,
            }
        case 'SignUpCompany':
            return {
                icon: <CheckCircleIconChakra width={width} strokeWidth={2} />,
            }
        case 'Login':
            return {
                icon: <LogInIconChakra width={width} strokeWidth={2} />,
            }

        // user
        case 'UserSnippet':
            return {
                icon: (
                    <Avatar
                        variant="user"
                        src={currentUser!.imageUrl}
                        boxSize="40px"
                        my="-8px"
                    />
                ),
            }
        case 'ProfileSettings':
            return {
                icon: <UserIconChakra width={width} strokeWidth={2} />,
            }
        case 'NotificationSettings':
            return {
                icon: <BellIconChakra width={width} strokeWidth={2} />,
            }
        case 'CompanySnippet':
            return {
                icon: (
                    <Avatar
                        variant="company"
                        src={currentUser!.company?.logo}
                        boxSize="40px"
                        my="-8px"
                    />
                ),
            }
        case 'CompanySettings':
            return {
                icon: <SettingsIconChakra width={width} strokeWidth={2} />,
            }
        case 'Metrics':
            return {
                icon: <SpacecraftIconChakra width={width} px="1.5px" strokeWidth={0} />,
                svgHoverStrategy: 'both',
            }
        case 'Feed':
            return {
                icon: <RssIconChakra width={width} strokeWidth={2} />,
            }
        case 'Logout':
            return {
                icon: (
                    <LeaveIconChakra
                        width={width}
                        px="1px"
                        ml="2px"
                        mr="-2px"
                        strokeWidth={2}
                    />
                ),
            }
    }

    // special case for communities icons
    const community = verticalOptions.find(opt => opt.value === name)
    if (community) {
        return {
            icon: <VerticalLogo vertical={community.value} boxSize="23px" />,
        }
    }
}
