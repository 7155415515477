import React, { ReactNode } from 'react'
import { Flex, Grid } from '@chakra-ui/react'
import { HeaderItemName } from 'components/appShell/header/structure'

const DefaultMenuItemsWrapper = ({ children }: { children?: ReactNode }) => {
    return (
        <Flex
            direction="column"
            alignItems="start"
            gridGap="26px"
            border="none"
            _focus={{ outline: 'none' }}
        >
            {children}
        </Flex>
    )
}

const CommunitiesMenuItemsWrapper = ({ children }: { children?: ReactNode }) => {
    return (
        <Grid
            rowGap="23px"
            columnGap="26px"
            gridTemplateColumns="1fr 1fr"
            gridTemplateRows="1fr 1fr 1fr 1fr"
            _focus={{ outline: 'none' }}
        >
            {children}
        </Grid>
    )
}

export const getMenuItemsWrapper = (name: HeaderItemName) => {
    switch (name) {
        case 'communities':
            return CommunitiesMenuItemsWrapper
        default:
            return DefaultMenuItemsWrapper
    }
}
