import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import Link, { LinkProps } from 'next/link'

export const ButtonLink = ({
    label,
    linkProps,
    ...buttonProps
}: {
    label: string
    linkProps: LinkProps
} & ButtonProps) => {
    return (
        <Link {...linkProps} passHref>
            <Button as="a" {...buttonProps}>
                {label}
            </Button>
        </Link>
    )
}
