import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { Menu as MenuIcon, X as XIcon } from '@emotion-icons/feather'

export const BurgerMenu = ({
    isMenuOpen,
    onMenuOpen,
    onMenuClose,
}: {
    isMenuOpen: boolean
    onMenuOpen: () => void
    onMenuClose: () => void
}) => {
    return (
        <IconButton
            variant="custom" // mobile only
            alignSelf="center"
            height="40px"
            padding="8px"
            margin="0 -8px"
            color="text.500"
            icon={
                isMenuOpen ? (
                    <XIcon width="24px" height="24px" strokeWidth={1.5} />
                ) : (
                    <MenuIcon width="24px" height="24px" strokeWidth={1.5} />
                )
            }
            aria-label={'Open Menu'}
            _focus={{ boxShadow: 'none' }}
            onClick={isMenuOpen ? onMenuClose : onMenuOpen}
        />
    )
}
