import React from 'react'
import { Divider } from '@chakra-ui/react'
import { Me } from 'entities/user'
import useMe from 'hooks/useMe'
import useVerticalData, { VerticalData } from 'hooks/useVerticalData'
import { NavMenuItemLink } from 'components/appShell/header/components/items/NavLinks'
import { getMenuItemProps } from 'components/appShell/header/components/items/menus/items/MenuItemProps'
import {
    HeaderItemName,
    Menu,
    MenuItemName,
    getMenuItem,
    getMenuItemsNames,
} from 'components/appShell/header/structure'

export const MenuItems = ({
    headerItemName,
    menu,
    forMobile,
    expanded,
}: {
    headerItemName: HeaderItemName
    menu: Menu<MenuItemName>
    forMobile?: boolean
    expanded?: boolean
}) => {
    const currentUser = useMe()
    const verticalData = useVerticalData()

    return (
        <>
            {getMenuItemsNames(menu, forMobile).map((menuItemName, idx) => {
                if (menuItemName === 'Divider') {
                    return <Divider key={`div-${idx}`} color="gray.dividers" my="14px" />
                } else {
                    return (
                        <MenuItem
                            key={idx}
                            headerItemName={headerItemName}
                            menuItemName={menuItemName}
                            currentUser={currentUser}
                            verticalData={verticalData}
                            forMobile={forMobile}
                            expanded={expanded}
                        />
                    )
                }
            })}
        </>
    )
}

const MenuItem = ({
    headerItemName,
    menuItemName,
    currentUser,
    verticalData,
    forMobile,
    expanded,
}: {
    headerItemName: HeaderItemName
    menuItemName: MenuItemName
    currentUser: Me
    verticalData: VerticalData
    forMobile?: boolean
    expanded?: boolean
}) => {
    const menuItem = getMenuItem(menuItemName)

    // NB: Menu items are meaningless w/o the label and/or URL.
    if (!menuItem.label || !menuItem.url) {
        return null
    }

    const props = getMenuItemProps(
        headerItemName,
        menuItemName,
        menuItem,
        currentUser,
        verticalData,
    )

    return <NavMenuItemLink {...props} forMobile={forMobile} expanded={expanded} />
}
