import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import Link from 'next/link'
import { FileText as FileTextIcon } from '@emotion-icons/feather'
import { gql } from '@urql/core'
import { StateFrequency } from 'entities/application'
import { routes } from 'routes'
import { isServerSide } from 'lib/env'
import { useMeForPrivatePage } from 'hooks/useMe'
import useQuery from 'hooks/useQuery'
import { BadgeNav } from 'components/appShell/header/components/items/statusIcons/BadgeNav'

const FileTextIconChakra = chakra(FileTextIcon)

const QUERY_APPLICATIONS_STATE_FREQUENCIES_ADMIN = gql<{
    application_state_frequencies: StateFrequency[]
}>`
    query application_state_frequencies_admin {
        application_state_frequencies {
            state
            count
        }
    }
`

const QUERY_APPLICATIONS_STATE_FREQUENCIES_COMPANY = gql<
    {
        application_state_frequencies: StateFrequency[]
    },
    { company_id: string }
>`
    query application_state_frequencies_company($company_id: String!) {
        application_state_frequencies(company_id: $company_id) {
            state
            count
        }
    }
`

export const CompanyApplications = () => {
    const currentUser = useMeForPrivatePage()

    const [resultAdmin] = useQuery({
        query: QUERY_APPLICATIONS_STATE_FREQUENCIES_ADMIN,
        pause: isServerSide || !currentUser.isAdmin,
    })

    const [resultCompany] = useQuery({
        query: QUERY_APPLICATIONS_STATE_FREQUENCIES_COMPANY,
        variables: { company_id: currentUser.companyId! },
        pause: isServerSide || !currentUser.companyId || !currentUser.isCompanyUser,
    })

    const frequencies =
        (resultAdmin.data?.application_state_frequencies ||
            resultCompany.data?.application_state_frequencies) ??
        []

    const applicationsCount = frequencies.find(freq =>
        currentUser.isAdmin ? freq.state === 'pending' : freq.state === 'approved',
    )?.count

    return (
        <Link href={routes.userApplications(currentUser)} passHref>
            <ChakraLink
                position="relative"
                variant="menuItem"
                lineHeight="20px"
                p="8px"
                title="Applications"
                aria-label="Applications"
            >
                {applicationsCount && <BadgeNav>{applicationsCount}</BadgeNav>}
                <FileTextIconChakra
                    w="24px"
                    mr="-1px"
                    h="24px"
                    mt="-6px"
                    mb="-1px"
                    strokeWidth={1.3}
                />
            </ChakraLink>
        </Link>
    )
}
